<template>
  <div v-if="tournament">
    <div class="d-flex justify-content-between mt-4 mb-2">
      <div v-if="tournament.name">
        <h1>{{ tournament.name }}</h1>
        {{ `${formatDate(tournament.startDate, "dd-MM-yyyy")}` }}
      </div>

      <div class="float-right">
        <img
          v-if="tournament.recognizedByWorldParaAthletics"
          src="https://foysautournamentprod.blob.core.windows.net/public/World_Para_Athletics_logo.png"
          v-b-popover.hover.top="
            $t('tournament.world_para_athletics_description')
          "
          class="tournament-picture-size mr-3 d-inline"
        />
        <img
          v-if="tournament.recognizedByWorldAthletics"
          v-b-popover.hover.top="$t('tournament.world_athletics_description')"
          src="https://foysautournamentprod.blob.core.windows.net/public/22WA_Rankings_Competition_Logo_RGB.png"
          class="tournament-picture-size mr-3 d-inline"
        />
        <img
          v-if="tournament.pictureUrl"
          :src="tournament.pictureUrl"
          class="tournament-picture-size d-inline"
        />
      </div>
    </div>

    <hr class="orange" />

    <div class="my-2">
      <div
        :class="`btn mr-2 ` + getBtnClass(pageNames.Details)"
        @click="
          $router.push({
            name: 'Overview',
            query: { id: tournament.id },
          })
        "
      >
        {{ $t("tournament.details") }}
      </div>

      <template v-if="tournament.registrationSystem">
        <div
          :class="`btn mr-2 ` + getBtnClass(pageNames.Registrations)"
          @click="openRegistrations"
        >
          {{ $t("common.registrations") }}
        </div>

        <div
          :class="`btn mr-2 ` + getBtnClass(pageNames.Participants)"
          @click="openParticipants"
        >
          {{ $t("common.participants") }}
        </div>

        <div
          :class="'btn mr-2 ' + getBtnClass(pageNames.TimeSchedule)"
          @click="openTimeschedule"
        >
          {{ $t("tournament.timeschedule") }}
        </div>

        <div
          :class="'btn mr-2 ' + getBtnClass(pageNames.Results)"
          @click="openResults"
        >
          {{ $t("common.results") }}
        </div>
      </template>

      <div class="float-right">
        <div
          class="btn btn-outline-secondary"
          @click="$router.push({ name: 'Overview' })"
        >
          {{ $t("tournament.back") }}
        </div>
      </div>
    </div>

    <hr class="orange" />
  </div>
</template>

<script>
import { registrationSystemsEnum, pageNamesEnum } from "@/shared";

export default {
  mixins: [registrationSystemsEnum, pageNamesEnum],
  props: {
    tournament: {},
  },
  computed: {
    isSeltecRegistrationSystem() {
      return (
        this.tournament.registrationSystem === this.registrationSystems.Seltec
      );
    },
    isAtletiekNuRegistrationSystem() {
      return (
        this.tournament.registrationSystem ===
        this.registrationSystems.AtleticNU
      );
    },
  },
  methods: {
    getBtnClass(pageName) {
      if (pageName === this.pageNames.Details && !this.$route.query.page) {
        return "btn-primary";
      }

      return this.$route.query.page == pageName
        ? "btn-primary"
        : "btn-outline-secondary";
    },
    navigateToRoute(routeParam) {
      this.$router.push({
        query: { id: this.tournament.id, page: routeParam },
      });
    },
    openUrlInNewTab(url) {
      window.open(url, "_blank").focus();
    },
    openTimeschedule() {
      if (this.isSeltecRegistrationSystem) {
        this.navigateToRoute(this.pageNames.TimeSchedule);
      } else if (this.isAtletiekNuRegistrationSystem) {
        var url = "https://www.atletiek.nu/";
        window.open(url, "_blank").focus();
      }
    },
    openRegistrations() {
      if (this.isSeltecRegistrationSystem) {
        this.openUrlInNewTab(
          `https://lanet3.nl/external/volta/register/${this.tournament.id}`
        );
      } else if (
        this.isAtletiekNuRegistrationSystem &&
        this.tournament.externalId
      ) {
        this.openUrlInNewTab(
          `https://www.atletiek.nu/wedstrijd/inschrijven/${this.tournament.externalId}`
        );
      }
    },
    openResults() {
      if (this.isSeltecRegistrationSystem) {
        this.navigateToRoute(this.pageNames.Results);
      } else if (
        this.isAtletiekNuRegistrationSystem &&
        this.tournament.externalId
      ) {
        this.openUrlInNewTab(
          `https://www.atletiek.nu/wedstrijd/uitslagen/${this.tournament.externalId}`
        );
      }
    },
    openParticipants() {
      if (this.isSeltecRegistrationSystem) {
        this.navigateToRoute(this.pageNames.Participants);
      } else if (
        this.isAtletiekNuRegistrationSystem &&
        this.tournament.externalId
      ) {
        this.openUrlInNewTab(
          `https://www.atletiek.nu/wedstrijd/atleten/${this.tournament.externalId}`
        );
      }
    },
  },
};
</script>
